import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import { Loading } from 'vant';

Vue.use(Loading);
Vue.config.productionTip = false;

Vue.config.ignoredElements = ['wx-open-launch-weapp'];

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
