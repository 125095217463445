<template>
  <div class="home-page">
    <div class="btnArea" v-show="form == 'micromessenger'">
      <wx-open-launch-weapp
        class="btnjd"
        id="launch-btn"
        username="gh_45b306365c3d"
        path="pages/shop/index/index?shopId=10454314"
        @ready="ready"
        @launch="launch"
        @error="error"
      >
        <script type="text/wxtag-template">
          <style>
            .btn{
              margin: 0px;
              padding: 0px;
              border: 0px;
              outline: none;
              width: 165px;
              height: 35px;
              opacity: 0;
            }
          </style>

          <button class="btn"></button>
        </script>
      </wx-open-launch-weapp>
      <!-- <a href="https://ur.alipay.com/5Qf5kC"  class="btntm"></a> -->

      <!-- <router-link to="/linkTm" class="btntm"></router-link> -->
      <div class="btntm" @click="showModal = true"></div>
    </div>

    <div class="btnArea" v-show="form != 'micromessenger'">
      <a
        href="http://mall.jd.com/qr/v.html?type=js&Id=10454314&src=qr"
        class="btnjd"
      ></a>
      <a href="https://ur.alipay.com/5Qf5kC" class="btntm"></a>
    </div>

    <!-- 弹框 -->
    <div class="home-page-modal" v-if="showModal">
      <div class="home-page-modal-mask" @click="showModal = false"></div>
      <div class="home-page-modal-content">
        <div class="home-page-modal-content-arr"></div>
        <div class="home-page-modal-content-item">
          1、点击右上角的
          <img
            style="width: 30px; height: 20px; margin-left: 8px"
            :src="point"
          />
          <div style="margin-left: 8px">按钮</div>
        </div>
        <div class="home-page-modal-content-item">
          2、按图中选择【在浏览器
          <img
            style="width: 30px; height: 30px; margin-left: 8px"
            :src="global"
          />
          <div style="margin-left: 8px">中打开】</div>
        </div>
        <div class="home-page-modal-content-modal"></div>
        <p class="home-page-modal-content-item">3、选择【浏览器】图标</p>
        <p class="home-page-modal-content-item">4、在浏览器中点击“上天猫购”</p>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
// import { axios } from './utils/request.js';
import api from "../api/https";
import point from "../assets/icons/point.jpg";
import global from "../assets/icons/global.png";

var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?4e546df88b5e2632c0b1a70f1c9362d1";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

export default {
  name: "App",
  data() {
    return {
      form: "",
      point,
      global,
      showModal: false,
      jsonextra: "",
    };
  },
  methods: {
    aaaa() {
      console.log("d");
      wx.miniProgram.navigateTo({
        url: "/pagesA/my/myCoupons",
      });
    },
    ready(e) {
      console.log("ready1", e);
    },
    launch(e) {
      console.log("success1", e);
    },
    error(e) {
      console.log("fail2", e);
    },
    async getCode() {
      const code = this.getURLParameter("code");
      //   console.log('----code',code);
      // this.code = code
      if (code) {
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          this.form = "micromessenger";
        } else {
          this.form = "";
        }
        // if(this.getCookie('code'))
        if (this.getCookie("code")) {
          //   console.log('有旧的code');
          let oldCode = this.getCookie("code");
          if (oldCode == code) {
            if (this.getCookie("wxdata")) {
              let oldData = JSON.parse(this.getCookie("wxdata"));
              //   console.log('且和当前的相同，用旧数据',oldData);
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: oldData.data.jsConfig.appId, // 必填，公众号的唯一标识
                timestamp: JSON.stringify(oldData.data.jsConfig.timestamp), // 必填，生成签名的时间戳
                nonceStr: oldData.data.jsConfig.nonceStr, // 必填，生成签名的随机串
                signature: oldData.data.jsConfig.signature, // 必填，签名
                jsApiList: oldData.data.jsConfig.jsApiList, // 必填，需要使用的JS接口列表
                openTagList: oldData.data.jsConfig.openTagList, // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
              });
            } else {
              //   console.log('有旧code但没有旧date');
              this.getWxData(code);
            }
          } else {
            // console.log('且和当前的不同---->重新请求');
            this.getWxData(code);
          }
        } else {
          //   console.log('无旧的code');
          this.getWxData(code);
        }
      } else {
        console.log("回调");
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          this.form = "micromessenger";
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx50d7dea04ef3a9db&redirect_uri=https%3A%2F%2Fds.liquan.com%2Findex&response_type=code&scope=snsapi_userinfo&state=#wechat_redirect";
        } else if (ua.match(/Alipay/i) == "alipay") {
          this.form = "";
        } else {
          this.form = "";
          console.log("其他浏览器", ua);
        }
      }
    },
    async getWxData(code) {
      const _result = await api.get(
        `mbr/members/getWxJsConfigByCode?code=${code}&url=${encodeURIComponent(
          location.href.split("#")[0]
        )}`
      );

      if (_result.errcode == 0) {
        this.setCookie("wxdata", JSON.stringify(_result));
        this.setCookie("code", code);

        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: _result.data.jsConfig.appId, // 必填，公众号的唯一标识
          timestamp: JSON.stringify(_result.data.jsConfig.timestamp), // 必填，生成签名的时间戳
          nonceStr: _result.data.jsConfig.nonceStr, // 必填，生成签名的随机串
          signature: _result.data.jsConfig.signature, // 必填，签名
          jsApiList: _result.data.jsConfig.jsApiList, // 必填，需要使用的JS接口列表
          openTagList: _result.data.jsConfig.openTagList, // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        });
        // 通过ready接口处理成功验证
        wx.ready(function () {
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
          console.log("readysuccess");
        });
        // 通过error接口处理失败验证
        wx.error(function (res) {
          console.log("res", res);
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        });
        //sessionStorage.setItem('ACCESS_TOKEN', result.data.memberInfo.token);
      }
    },
    getURLParameter(variable) {
      const url = window.location.href;
      //  this.code = url
      const search = url.substring(url.lastIndexOf("?") + 1);

      const obj = {};
      const reg = /([^?&=]+)=([^?&=]*)/g;
      search.replace(reg, (rs, $1, $2) => {
        const name = decodeURIComponent($1);
        let val = decodeURIComponent($2);
        val = String(val);
        obj[name] = val;
        return rs;
      });
      return obj[variable];
    },
    setCookie(c_name, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        c_name +
        "=" +
        escape(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
    },
    //取回cookie
    getCookie(c_name) {
      let c_start = "";
      let c_end = "";
      if (document.cookie.length > 0) {
        c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
          c_start = c_start + c_name.length + 1;
          c_end = document.cookie.indexOf(";", c_start);
          if (c_end == -1) c_end = document.cookie.length;
          return unescape(document.cookie.substring(c_start, c_end));
        }
      }
      return "";
    },
  },
  created() {
    // console.log('created',window.location.href);
    this.getCode();
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  font-size: 100%;
}
button {
  /*消除button的默认样式*/
  /*这种写法是对所有的button标签同时生效*/
  margin: 0px;
  padding: 0px;
  /*自定义边框*/
  border: 0px;
  /*消除默认点击蓝色边框效果*/
  outline: none;
}
a {
  text-decoration: none;
}

.home-page {
  width: 100vw;
  height: calc(100vw / 750 * 1500);
  background: url("../assets/bg.png") no-repeat center center;
  // background-repeat:no-repeat;
  // background-position:center top;
  background-size: 100% auto, 100% auto;
  position: relative;
  .home-page-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .home-page-modal-mask {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 10;
    }
    .home-page-modal-content {
      padding: 30px;
      color: white;
      z-index: 20;
      .home-page-modal-content-arr {
        width: 50px;
        height: 100px;
        background: url("../assets/icons/arr.png") no-repeat center;
        background-size: 100%;
        position: absolute;
        top: -10px;
        right: 20px;
      }
      .home-page-modal-content-item {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
      }
      .home-page-modal-content-modal {
        width: 340px;
        height: 250px;
        background: url("../assets/icons/modal.png") no-repeat center;
        background-size: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .btnArea {
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: calc((100vw / 750 * 1450) - (100vw / 750 * 340));
    .btnjd {
      width: calc(100vw * 0.412);
      height: calc(100vw * 0.087);
      background: url("../assets/icons/jd.png") no-repeat center;
      background-size: 100%;
    }
    .btntm {
      width: calc(100vw * 0.412);
      height: calc(100vw * 0.087);
      background: url("../assets/icons/tmall.png") no-repeat center;
      background-size: 100%;
    }
  }
}
</style>
