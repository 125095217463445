import Vue from 'vue'
import VueRouter from 'vue-router'
import LinkTm from '../views/linkTm.vue'
import IndexPage from '../views/index.vue'

Vue.use(VueRouter)


//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

//定义routes路由的集合，数组类型
const routes=[
    //单个路由均为对象类型，path代表的是路径，component代表组件
    {
        path: '*',
        name: '主页面',
        redirect: '/index',
    },
    {
        path: '/index',
        name: 'Index',
        component: IndexPage
    },
    {
        path: '/linkTm',
        name: 'LinkTm',
        component: LinkTm
    },
]

//实例化VueRouter并将routes添加进去
const router = new VueRouter({
//ES6简写，等于routes：routes
    mode:'hash',
    routes
});

export default router;