<template>
  <div class="linkTm-page">
    <div class="linkTm-page-content">
      <div class="linkTm-page-conten-item">
        1、点击右上角的
        <img style="width: 30px; height: 20px; margin-left: 8px" :src="point" />
        <div style="margin-left: 8px">按钮</div>
      </div>
      <div class="linkTm-page-conten-item">
        2、按图中选择【在浏览器
        <img
          style="width: 30px; height: 30px; margin-left: 8px"
          :src="global"
        />
        <div style="margin-left: 8px">中打开】</div>
      </div>
      <div class="linkTm-page-conten-modal"></div>
      <p class="linkTm-page-conten-item">3、选择【浏览器】图标</p>
      <p class="linkTm-page-conten-item">4、在浏览器中点击“上天猫购”</p>
    </div>
    <div class="linkTm-page-arr"></div>
    <!-- <van-loading class="loadingBox" type="spinner" color="#1989fa" size="30px" v-show="loading"/>
    <iframe 
        ref="Iframe"
        style="width:100%;height:100%;"
        src="https://ur.alipay.com/5Qf5kC" 
        id="mobsf" scrolling="no" frameborder="0" 
    ></iframe> -->
  </div>
</template>

<script>
import point from "../assets/icons/point.jpg";
import global from "../assets/icons/global.png";

export default {
  name: "linkTm",
  data() {
    return {
      point,
      global,
      loading: true,
    };
  },
  methods: {
    iframeLoad() {
      this.loading = true;
      const iframe = this.$refs.Iframe;
      if (iframe.attachEvent) {
        // IE
        iframe.attachEvent("onload", () => {
          this.loading = false;
          // setTimeout(() => {
          //     this.loading = false;
          // }, 500);
        });
      } else {
        // 非IE
        iframe.onload = () => {
          this.loading = false;
          // setTimeout(() => {
          //     this.loading = false;
          // }, 500);
        };
      }
    },
  },
  components: {},
  created() {
    console.log("created");
    /**
     * iframe-宽高自适应显示
     */
    // this.$nextTick(function () {
    // const oIframe = document.getElementById('mobsf');
    //     const deviceWidth = document.documentElement.clientWidth;
    //     const deviceHeight = document.documentElement.clientHeight;
    //     oIframe.style.width = deviceWidth + 'px';
    //     oIframe.style.height = deviceHeight + 'px';
    //     this.iframeLoad();
    // })
  },
};
</script>

<style lang="less">
.linkTm-page {
  height: 100vh;
  width: 100vw;
  background: #313131;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: relative;
  .linkTm-page-content {
    padding: 30px;
    color: white;
    .linkTm-page-conten-item {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
    .linkTm-page-conten-modal {
      width: 340px;
      height: 250px;
      background: url("../assets/icons/modal.png") no-repeat center;
      background-size: 100%;
      margin-bottom: 10px;
    }
  }
  .linkTm-page-arr {
    width: 50px;
    height: 100px;
    background: url("../assets/icons/arr.png") no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 0;
    right: 20px;
  }
}
.loadingBox {
  height: 100px;
  position: fixed;
  left: 45%;
  right: 0;
  top: 45%;
  bottom: 0;
  z-index: 999;
}
</style>
