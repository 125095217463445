// 引入依赖包
import axios from "axios";
import qs from 'qs'
import { api_HOST } from "../config/index";

// 创建axios实例
const ajax = axios.create({
  baseURL: api_HOST,
  timeout: 60000,
  headers: {
  }
});

// 添加接口请求拦截器
ajax.interceptors.request.use(
  config => {
    if (
      config.headers["Content-Type"] === "application/x-www-form-urlencoded"
    ) {
      config.transformRequest = [
        function(data) {
          data = qs.stringify(data);
          return data;
        }
      ];
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.resolve(error);
  }
);

// 添加接口响应拦截器
ajax.interceptors.response.use(
  response => {
    // console.log(response);
    // 对响应数据做点什么
    switch (response.data.code) {
      case 200:
        break;
      case 401:
        break;
      default:
        // Taro.showToast({
        //   title: response.data.msg,
        //   icon: "none",
        //   duration: 2000
        // });
        break;
    }
    return response;
  },
  error => {
    return Promise.resolve(error.response);
  }
);

// 定义请求方法
export default {
  get: (url = "", data = {}, option = {}) => {
    return ajax({
      url,
      method: "get",
      params: data,
      ...option
      // cancelToken: axiosSource.token
    }).then(response => response.data);
  },
  post: (url = "", data = {}, option = {}) => {
    return ajax({
      url,
      method: "post",
      data: data,
      ...option
      // cancelToken: axiosSource.token
    }).then(response => response.data);
  },
  put: (url = "", data = {}, option = {}) => {
    return ajax({
      url,
      method: "put",
      data: data,
      ...option
      // cancelToken: axiosSource.token
    }).then(response => response.data);
  },
  delete: (url = "", data = {}, option = {}) => {
    return ajax({
      url,
      method: "delete",
      data: data,
      ...option
      // cancelToken: axiosSource.token
    }).then(response => response.data);
  },
  init: (list = []) => {
    return Promise.all(list).then(values => values);
  }
};
